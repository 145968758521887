import {writable} from 'svelte/store'

export const filterSettings = (() => {

    const initValue = {
        search: '',
        sortAlph: '',
        words: '',
        art: '',
        level: '',
        frequency: '',
        syllables: '',
    }

    // init & reset spreaded Version, damit Obj durchs filter nicht verändert wird!
    const {subscribe, set, update} = writable({...initValue})

    function resetSearch() {
        update(value => {
            value.search = ''
            return value
        })
    }

    function resetWords() {
        update(value => {
            value.words = ''
            return value
        })
    }

    function resetStatic() {
        update(value => {
            value.art = ''
            value.level = ''
            value.frequency = ''
            value.syllables = ''
            return value
        })
    }

    function resetWordsAndStatic() {
        resetWords()
        resetStatic()
    }

    function resetAll() {
        set({...initValue})
    }

    return {
        subscribe,
        set, // direct bind:value from search input!
        resetSearch,
        resetStatic,
        resetWordsAndStatic,
        resetAll
    }
})();


